import React from 'react'
// import { Link } from 'gatsby';
import styled from 'styled-components';

const FourOhFourStyles = styled.main`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;

    div {
        text-align: center;
        margin-bottom: 16px;
    }
    a {
        font-weight: 700;
        font-size: 36px;
        color: rgb(255, 99, 212);
    }
`


export default function FourOhFourPage(props) {
    // console.log(props)
    return (
        <FourOhFourStyles>
            <div>Sorry, that page does not exist</div>
            <a href="/">HOME</a>
        </FourOhFourStyles>
    );
}

// export const query = graphql`
// query {
// }
  
// `